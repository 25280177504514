import { WEBGL } from 'three/examples/jsm/WebGL.js';
import { Viewer } from './viewer.js';
import { SimpleDropzone } from 'simple-dropzone';
import { ValidationController } from './validation-controller.js';
import queryString from 'query-string';

if (!(window.File && window.FileReader && window.FileList && window.Blob)) {
  console.error('The File APIs are not fully supported in this browser.');
} else if (!WEBGL.isWebGLAvailable()) {
  console.error('WebGL is not supported in this browser.');
}

class App {

  /**
   * @param  {Element} el
   * @param  {Location} location
   */
  constructor (el, location) {

    const hash = location.hash ? queryString.parse(location.hash) : {};
    this.options = {
      kiosk: Boolean(hash.kiosk),
      model: hash.model || '',
      preset: hash.preset || '',
      cameraPosition: hash.cameraPosition
        ? hash.cameraPosition.split(',').map(Number)
        : null
    };

    const queryParams = location.search ? queryString.parse(location.search) : {};

    this.el = el;
    this.viewer = null;
    this.viewerEl = null;
    this.spinnerEl = el.querySelector('.spinner');
    this.dropEl = el.querySelector('.dropzone');
    this.inputEl = el.querySelector('#file-input');

    if (queryParams.coin && queryParams.model){
      // replace page title
      document.getElementById("page-title").innerHTML = queryParams.coin;

      // hide the links to the other samples
      document.getElementById('gold-sample').style.display = 'none';
      document.getElementById('silver-sample').style.display = 'none';
      document.getElementById('copper-sample').style.display = 'none';
      let elsToHide = document.getElementsByClassName("separator");
      for(var i = 0; i < elsToHide.length; i++){
        elsToHide[i].style.display = "none";
      }

      // load model:
      this.view(`https://api1.staking.rocks/ipfs/${queryParams.model}`);

    } else {
      // no query params; no specific model to be viewed
      // show samples instead
      this.view("https://api1.staking.rocks/ipfs/QmeoZ243x8MfqTkaTa4vYBG92TtuCs1oB56HTxQwoxJkgG");
      //this.view("https://ipfs.io/ipfs/QmUeWyPNGbtQk79kjToCVJcL1yDpSmRsHDjAg9maJ2aQJH");

    }

    const options = this.options;

    if (options.kiosk) {
      const headerEl = document.querySelector('header');
      headerEl.style.display = 'none';
    }

    if (options.model) {
      this.view(options.model, '', new Map());
    }

    
    // click listeners for the other samples:
    let app = this;
    document.getElementById("gold-sample").addEventListener("click", (e) => {
      e.preventDefault();
      app.view("https://api1.staking.rocks/ipfs/QmeoZ243x8MfqTkaTa4vYBG92TtuCs1oB56HTxQwoxJkgG");
    });
    document.getElementById("silver-sample").addEventListener("click", (e) => {
      e.preventDefault();
      app.view("https://api1.staking.rocks/ipfs/QmRA5Toyvvbh9ccyyzMN9nyKkS57kEoMKRoqiDi25tCWRw");
    });
    document.getElementById("copper-sample").addEventListener("click", (e) => {
      e.preventDefault();
      app.view("https://api1.staking.rocks/ipfs/QmdVmbyFhohuN2hdfx7wiCUKzBeL1sm3PZ4NZwsfR9UKJB");
    });


  }

  /**
   * Sets up the drag-and-drop controller.
   */
  createDropzone () {
    const dropCtrl = new SimpleDropzone(this.dropEl, this.inputEl);
    dropCtrl.on('drop', ({files}) => this.load(files));
    dropCtrl.on('dropstart', () => this.showSpinner());
    dropCtrl.on('droperror', () => this.hideSpinner());
  }

  /**
   * Sets up the view manager.
   * @return {Viewer}
   */
  createViewer () {
    this.viewerEl = document.createElement('div');
    this.viewerEl.classList.add('viewer');
    this.dropEl.innerHTML = '';
    this.dropEl.appendChild(this.viewerEl);
    this.viewer = new Viewer(this.viewerEl, this.options);
    return this.viewer;
  }

  /**
   * Loads a fileset provided by user action.
   * @param  {Map<string, File>} fileMap
   */
  load (fileMap) {
    let rootFile;
    let rootPath;
    Array.from(fileMap).forEach(([path, file]) => {
      if (file.name.match(/\.(gltf|glb)$/)) {
        rootFile = file;
        rootPath = path.replace(file.name, '');
      }
    });

    if (!rootFile) {
      this.onError('No .gltf or .glb asset found.');
    }
    this.view(rootFile, rootPath, fileMap);
  }

  /**
   * Passes a model to the viewer, given file and resources.
   * @param  {File|string} rootFile
   * @param  {string} rootPath
   * @param  {Map<string, File>} fileMap
   */
  view (rootFile, rootPath="/", fileMap=new Map()) {

    if (this.viewer) this.viewer.clear();

    const viewer = this.viewer || this.createViewer();

    //console.log("typeof rootFile: ", typeof rootFile);

    const fileURL = typeof rootFile === 'string'
      ? rootFile
      : URL.createObjectURL(rootFile);

    const cleanup = () => {
      this.hideSpinner();
      if (typeof rootFile === 'object') URL.revokeObjectURL(fileURL);
    };

    viewer
      .load(fileURL, rootPath, fileMap)
      .catch((e) => this.onError(e))
      .then(() => {
        cleanup();
      });
  }

  /**
   * @param  {Error} error
   */
  onError (error) {
    let message = (error||{}).message || error.toString();
    if (message.match(/ProgressEvent/)) {
      message = 'Unable to retrieve this file. Check JS console and browser network tab.';
    } else if (message.match(/Unexpected token/)) {
      message = `Unable to parse file content. Verify that this file is valid. Error: "${message}"`;
    } else if (error && error.target && error.target instanceof Image) {
      message = 'Missing texture: ' + error.target.src.split('/').pop();
    }
    window.alert(message);
    console.error(error);
  }

  showSpinner () {
    this.spinnerEl.style.display = '';
  }

  hideSpinner () {
    this.spinnerEl.style.display = 'none';
  }
}

document.addEventListener('DOMContentLoaded', () => {

  const app = new App(document.body, location);

});
